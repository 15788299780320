<tabset [ngClass]="hideHeader ? 'non-header' : ''" id="tabsetTableComponent" #tablesTabSet>
  <tab
    (selectTab)="onSelectedTab(tab, t)"
    heading="{{
      tab.title
        ? tab.title + '(' + (tab.settings?.paging?.totalItems || tab?.dataTable?.length || 0) + ')'
        : ''
    }}"
    customClass="custom-tab"
    [id]="tab.tableId"
    *ngFor="let tab of tabs; let t = index"
  >
    <a
      class="btn btn-mixtape mt20 pull-right"
      *ngIf="tab.settings?.enableEdition"
      (click)="toggleEdition(tab)"
    >
      {{ tab.settings?.isEditing ? 'Cancel' : 'Edit' }}
    </a>
    <a
      class="btn btn-mixtape mt20 pull-right"
      *ngIf="tab.settings?.isEditing"
      (click)="saveBulk(tab)"
    >
      Save bulk
    </a>
    <div class="row ml0 mr0" [ngClass]="{ mt10: !noMTop }">
      <div class="col-sm-12 pl0 {{ customPR }}">
        <div style="overflow: auto">
          <table
            class="tableFixHead mb20"
            [ngStyle]="{ 'min-width': minWidth ? minWidth : 'none' }"
          >
            <thead class="mobile-hidden">
              <tr>
                <th
                  *ngFor="let col of tab.settings?.columns"
                  [hidden]="!col.show"
                  [ngStyle]="{
                    width: col.widthType
                      ? col.width + col.widthType
                      : (col.width || 100 / tab.settings?.columns?.length) + '%',
                    'text-align': col.alignment || 'left',
                    'min-width': col.title === '' ? '10px' : '5%'
                  }"
                  class="pt5"
                >
                  <p>
                    {{ col.title }}
                    <i
                      *ngIf="col.tooltipText"
                      class="fa fa-info-circle primary-link pl5"
                      [tooltip]="col.tooltipText"
                      [adaptivePosition]="false"
                      [container]="'body'"
                      [placement]="col.tooltipPlacement || 'top'"
                    >
                    </i>
                  </p>
                  <span
                    *ngIf="col.sortable"
                    (click)="
                      sortByColumn(
                        tab,
                        col.field,
                        !tab.settings?.sorting[col.field] ||
                          tab.settings?.sorting[col.field] === 'asc'
                          ? 'desc'
                          : 'asc'
                      )
                    "
                  >
                    <i
                      class="fa fa-chevron-up sort-style"
                      aria-hidden="true"
                      [ngClass]="{
                        'sort-option': tab.settings?.sorting[col.field],
                        'no-sort-up': !tab.settings?.sorting[col.field],
                        'no-sort':
                          tab.settings?.sorting[col.field] &&
                          tab.settings?.sorting[col.field] !== 'asc'
                      }"
                    ></i>
                    <i
                      class="fa fa-chevron-down sort-style no-sort-down"
                      aria-hidden="true"
                      [ngClass]="{
                        'sort-option': tab.settings?.sorting[col.field],
                        'no-sort-up': !tab.settings?.sorting[col.field],
                        'no-sort':
                          tab.settings?.sorting[col.field] &&
                          tab.settings?.sorting[col.field] !== 'desc'
                      }"
                    ></i>
                  </span>
                </th>
              </tr>
              <tr class="filter-section">
                <td
                  *ngFor="let col of tab.settings?.columns"
                  [hidden]="!col.show"
                  [ngStyle]="{
                    width: col.widthType
                      ? col.width + col.widthType
                      : (col.width || 100 / tab.settings?.columns?.length) + '%',
                    'text-align': col.alignment || 'left',
                    'min-width': col.title === '' ? '10px' : '5%',
                    'padding-left': col.padding || 20
                  }"
                >
                  <input
                    data-hj-allow
                    *ngIf="
                      col.filter && col.filter?.kind !== 'dropdown' && col.filter !== 'filterArray'
                    "
                    type="text"
                    class="pl5"
                    class="form-control"
                    placeholder="{{ col.placeholder }}"
                    (input)="filterByColumn($event.target.value, tab, col.field, col)"
                    (keydown)="$event.stopPropagation()"
                  />
                  <div
                    *ngIf="
                      col.filter &&
                      col.filter.kind === 'dropdown' &&
                      col.filter.placeholder === 'Filter by status'
                    "
                    style="display: inline-block"
                    class="pl5"
                    [ngStyle]="{ width: col.filter?.width || '100%' }"
                    [ngClass]="{ 'minimalist-dropdown-multiselect': col?.minimalistDropdown }"
                  >
                    <angular2-multiselect
                      *ngIf="!col?.minimalistDropdown"
                      class="filterDropdown"
                      [data]="col.filter.options"
                      [settings]="dropdownSettings"
                      [(ngModel)]="dropdwonModel"
                      (onSelect)="onFilterSelected($event, tab, col.field, col)"
                      (onDeSelect)="onFilterDeselected($event, tab, col.field, col)"
                    >
                    </angular2-multiselect>
                    <angular2-multiselect
                      *ngIf="col?.minimalistDropdown"
                      class="filterDropdown"
                      [data]="col.filter.options"
                      [settings]="dropdownSettingsMinimalist"
                      [(ngModel)]="dropdwonModel"
                      (onSelect)="onFilterSelected($event, tab, col.field, col)"
                      (onDeSelect)="onFilterDeselected($event, tab, col.field, col)"
                    >
                    </angular2-multiselect>
                  </div>
                  <ng-container *ngIf="col.filterArray">
                    <ng-container *ngFor="let filter of col.filterArray; let iFilter = index">
                      <div
                        *ngIf="filter.kind && filter.kind === 'dropdown'"
                        style="display: inline-block"
                        [ngStyle]="{ width: filter.width || '100%' }"
                        class="pl0"
                        [ngClass]="{ 'minimalist-dropdown-multiselect': col?.minimalistDropdown }"
                      >
                        <angular2-multiselect
                          class="filterDropdown pl0"
                          [data]="filter.data"
                          [settings]="filter.settings"
                          [(ngModel)]="modelFilterDropdown[filter.model]"
                          (onSelect)="
                            onDropdownSelected($event, filter.model, filter.filter, filter.settings)
                          "
                          (onDeSelect)="onDropdownDeselected($event, filter.model, filter.filter)"
                        >
                        </angular2-multiselect>
                      </div>
                    </ng-container>
                  </ng-container>
                </td>
              </tr>
            </thead>
            <ng-container *ngIf="tab.replacingData">
              <tbody
                [style.height.px]="tab.settings?.pagination ? tableHeight - 60 : tableHeight"
                style="display: block"
              >
                <tr class="loading-row" *ngFor="let row of loadingRows">
                  <ng-container *ngFor="let col of tab?.settings?.columns">
                    <td [ngStyle]="{ width: col.width + 'px' }" *ngIf="col.show"></td>
                  </ng-container>
                </tr>
              </tbody>
            </ng-container>
            <ng-container *ngIf="!tab.replacingData">
              <tbody
                infiniteScroll
                [infiniteScrollDistance]="3"
                [infiniteScrollDisabled]="tab?.hasOwnProperty('loading') && tab.loading"
                [infiniteScrollThrottle]="1"
                (scrolled)="onTabScroll(tab)"
                (scrolledUp)="onTabScrollUp(tab)"
                (cdkDropListDropped)="onDropListDropped(tab, $event)"
                [scrollWindow]="false"
                [style.height.px]="tab.settings?.pagination ? tableHeight - 60 : tableHeight"
                cdkDropList
                [cdkDropListData]="tab.dataTable"
                [id]="tab.tableId"
              >
                <tr *ngIf="tab.settings?.newItem" class="items-row init-row">
                  <td [ngStyle]="{ 'padding-left': tab.settings?.newItem.padding || 20 }">
                    <p
                      class="mt5"
                      style="display: flex; align-items: center"
                      (click)="newElementList()"
                    >
                      <i class="material-icons pr5" aria-hidden="true" [attr.data-icon]="'add'"></i>
                      {{ tab.settings?.newItem.text }}
                    </p>
                  </td>
                </tr>
                <div
                  *ngIf="isMobile && tab.settings?.mobileCard; then mobileRow; else desktopRow"
                ></div>
                <ng-template #desktopRow>
                  <tr
                    *ngFor="
                      let data of tab.dataTable
                        | paginate
                          : {
                              itemsPerPage: tab.settings?.paging?.limit,
                              currentPage: tab.settings?.paging?.page,
                              totalItems: tab.settings?.paging?.totalItems + 1
                            };
                      let i = index
                    "
                    class="items-row"
                    [ngClass]="{
                      activeRow: data.hover,
                      evenRow: data.hasOwnProperty('isEvenRow') && data.isEvenRow,
                      oddRow: data.hasOwnProperty('isEvenRow') && !data.isEvenRow
                    }"
                    (click)="onRowSelected(tab, i, data)"
                    (mouseup)="onRowMouseUp($event, data)"
                    cdkDrag
                    [cdkDragData]="data"
                    (mouseenter)="onHover(data)"
                    (mouseleave)="onHoverOut(data)"
                    (cdkDragStarted)="onDragStarted(data)"
                    (cdkDragDropped)="onDragDropped()"
                    [cdkDragDisabled]="!dragDropComponent"
                  >
                    <td class="drag-sign" *ngIf="dragDropComponent">
                      <a>
                        <i class="fa fa-arrows-alt"></i>
                      </a>
                    </td>
                    <div
                      *ngIf="
                        data.hasOwnProperty('isFullyLoaded') ? data.isFullyLoaded : true;
                        then fullyLoadedTemplate;
                        else loaderTemplate
                      "
                    ></div>
                    <ng-template #loaderTemplate>
                      <td class="table-loader">
                        <div align="center">
                          <p>{{ data.loadingText }}</p>
                        </div>
                      </td>
                    </ng-template>
                    <ng-template #fullyLoadedTemplate>
                      <td
                        *ngFor="let col of tab.settings?.columns"
                        [hidden]="!col.show"
                        [ngStyle]="{
                          width: col.widthType
                            ? col.width + col.widthType
                            : (col.width || 100 / tab.settings?.columns?.length) + '%',
                          'text-align': col.alignment || 'left',
                          'padding-left': col.padding || 20,
                          'padding-right': col.paddingRight || 0
                        }"
                        class="transform-elm"
                        [ngClass]="{
                          'overflow-text':
                            col.dataType !== 'dropdown' && col.dataType !== 'multiselectDropdown'
                        }"
                      >
                        <div
                          *ngIf="
                            tab.settings?.isEditing;
                            then editionTemplate;
                            else regularTemplate
                          "
                        ></div>

                        <ng-template #regularTemplate>
                          <p *ngIf="col.dataType === 'edit-link'">
                            <a [routerLink]="[col.editLinkUrl, data[col.editUrlField]]">
                              {{ data[col.field] }}
                            </a>
                          </p>
                          <app-field-table
                            [col]="col"
                            [data]="data"
                            [tabID]="tab.tableId"
                            (rowAction)="getRowActionEmit($event)"
                          ></app-field-table>
                          <a
                            *ngIf="
                              col.dataType === 'icon' &&
                              col.hasOwnProperty('displayIf') &&
                              col.displayIf === data[col.field]
                            "
                            class="icon-container"
                          >
                            <i
                              class="{{ col.iconClass }} table-icon"
                              disabled="true"
                              (click)="
                                $event.stopPropagation(); getRowAction(tab.tableId, col, data, i)
                              "
                            ></i>
                          </a>
                          <a
                            *ngIf="
                              col.dataType === 'icon' &&
                              col.hasOwnProperty('displayNotIf') &&
                              col.displayNotIf !== data[col.field]
                            "
                            class="icon-container"
                          >
                            <i
                              class="{{ col.iconClass }} table-icon"
                              disabled="true"
                              (click)="getRowAction(tab.tableId, col, data, i)"
                            ></i>
                          </a>
                          <p *ngIf="col.dataType === 'songName' || col.dataType === 'fileName'">
                            {{ data['songName'] || data['fileName'] | hideExtension }}
                            {{ data['artistName'] ? ' by ' + data['artistName'] : '' }}
                            <span *ngIf="data.songVersion">
                              {{
                                data.songVersion === 'original'
                                  ? ''
                                  : data.songVersion !== 'instrumental'
                                  ? '(' + data.songVersion + ')'
                                  : ('' | titlecase)
                              }}
                              {{ data.hasOwnProperty('vocals') && !data.vocals ? '(Inst)' : '' }}
                            </span>
                            <span
                              *ngIf="
                                col.id === 'stagesTableFileName' &&
                                (data.status === 'icebox' || data.status === 'deleted')
                              "
                              [ngClass]="{
                                'status-icebox': data.status === 'icebox',
                                'status-deleted': data.status === 'deleted'
                              }"
                              >{{ data.status | titlecase }}</span
                            >
                          </p>
                          <div *ngIf="col.dataType === 'textConcat'">
                            <ng-container
                              *ngFor="let textProp of col.textConcat; let tcIndex = index"
                            >
                              <div
                                [hidden]="textProp.booleanValue && !data[textProp.field]"
                                [ngClass]="tcIndex === 0 ? 'mt5 mb0' : 'mt0 mb5'"
                                [ngClass]="{ 'pull-left': textProp.join }"
                              >
                                <p *ngIf="!textProp.dataType" [class]="textProp.class">
                                  <span *ngIf="textProp.prefixDot" class="pr5"> &middot; </span>
                                  {{
                                    textProp.title
                                      ? textProp.title +
                                        '
                                  : '
                                      : ''
                                  }}{{ !textProp.booleanValue ? data[textProp.field] : '' }}
                                  {{
                                    textProp.booleanValue && data[textProp.field]
                                      ? textProp.booleanValue
                                      : ''
                                  }}
                                </p>
                                <p
                                  [class]="textProp.class"
                                  *ngIf="textProp.dataType && textProp.dataType === 'date'"
                                >
                                  {{ data[textProp.field] | date : 'mediumDate' }}
                                </p>
                              </div>
                            </ng-container>
                          </div>
                          <p *ngIf="col.dataType === 'text'">
                            {{
                              col.copyToChange && data[col.field] === col.copyToChange
                                ? col.newCopy + (data?.['action'] && col.addActionToCopy ? (' ' + data?.['action'] | lowercase) : '')
                                : data[col.field]
                            }}
                          </p>
                          <ng-container *ngIf="col.dataType === 'textAndIcon'">
                            <div style="width: 80%; float: left; overflow: hidden">
                              <p>{{ data[col.field] }}</p>
                            </div>
                            <a class="icon-container pl10" *ngIf="data.hovered">
                              <i
                                class="{{ col.iconClass }} table-icon"
                                disabled="true"
                                [tooltip]="data[col.iconTooltipField]"
                                >{{ col.iconText }}
                              </i>
                            </a>
                          </ng-container>
                          <p *ngIf="col.dataType === 'booleanText'">
                            {{ data[col.field] ? 'Yes' : 'No' }}
                          </p>
                          <p *ngIf="col.dataType === 'command'">{{ data[col.field] }}</p>
                          <p *ngIf="col.dataType === 'date' && data[col.field]">
                            {{
                              col.dateFormat
                                ? moment(data[col.field]).format(col.dateFormat)
                                : (data[col.field] | date : 'mediumDate')
                            }}
                          </p>
                          <div *ngIf="col.dataType === 'progress'">
                            <div
                              class="progress"
                              style="height: 10px; margin-bottom: auto"
                              *ngIf="data.hasOwnProperty([col.field])"
                            >
                              <div
                                class="progress-bar"
                                role="progressbar"
                                [ngStyle]="{ width: data[col.field] + '%' }"
                              ></div>
                            </div>
                            <div *ngIf="!data.hasOwnProperty([col.field])">
                              <p *ngIf="!data.progressStatus">Successfully loaded!</p>
                              <p *ngIf="data.progressStatus">{{ data.progressStatus.label }}</p>
                            </div>
                          </div>
                          <div *ngIf="col.dataType === 'dropdown'">
                            <div id="dropdown{{ i }}" class="btn-group dropdown">
                              <a
                                id="button-basic{{ i }}"
                                (click)="openDropdown($event, i)"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <h4 class="now-playing">
                                  {{ getTextDropdown(data, col) }}
                                  <i
                                    [ngClass]="
                                      col.iconDropdown ? col.iconDropdown : 'fa fa-sort-desc'
                                    "
                                    class="dropdown-desc ml5"
                                    style="bottom: 2px; position: relative"
                                    aria-hidden="true"
                                  ></i>
                                </h4>
                              </a>
                              <ul
                                id="dropdown-basic{{ i }}"
                                class="dropdown-menu"
                                [ngClass]="{
                                  'dropdown-menu-right':
                                    col.dropdownAlignment && col.dropdownAlignment === 'left'
                                }"
                              >
                                <li *ngFor="let option of col.ddlItems" aria-hidden="true">
                                  <a
                                    (click)="
                                      data[col.field] = col.objType ? option : option.label;
                                      getRowAction(tab.tableId, col, data, i, option);
                                      $event.stopPropagation()
                                    "
                                  >
                                    <i
                                      *ngIf="option.icon"
                                      class="{{ option.icon }}"
                                      aria-hidden="true"
                                    ></i>
                                    <p class="dropdown-text">
                                      {{ option.label }}
                                    </p>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <a *ngIf="col.dataType === 'boolean'" class="icon-container">
                            <i
                              *ngIf="data[col.field] === false"
                              class="{{ col.iconFalse }} table-icon"
                              disabled="true"
                              (click)="
                                $event.stopPropagation(); getRowAction(tab.tableId, col, data, i)
                              "
                            ></i>
                            <i
                              *ngIf="data[col.field] === true"
                              class="{{ col.iconTrue }} table-icon"
                              disabled="true"
                              (click)="
                                $event.stopPropagation(); getRowAction(tab.tableId, col, data, i)
                              "
                            ></i>
                          </a>
                          <div *ngIf="col.dataType === 'replaceIfDefined'">
                            <span
                              *ngIf="
                                col.definedTrue && data[col.field] && data[col.field] !== false
                              "
                              class="{{ col.definedTrueClass }}"
                            >
                              {{ col.definedTrue }}
                            </span>
                            <span
                              *ngIf="
                                col.definedFalse && (!data[col.field] || data[col.field] === false)
                              "
                              class="{{ col.definedFalseClass }}"
                            >
                              {{ col.definedFalse }}
                            </span>
                          </div>
                          <div
                            *ngIf="col.dataType === 'checkbox' && col.show"
                            class="d-flex align-items-start"
                          >
                            <label>
                              <input
                                type="checkbox"
                                [checked]="data[col.field]"
                                [disabled]="col.warningField && !data[col.warningField]"
                                (change)="getRowAction(tab.tableId, col, data, i, col.label)"
                              />
                              {{ col.label }}
                            </label>
                            <span
                              *ngIf="col.warningField && !data[col.warningField]"
                              class="material-icons-outlined pl5"
                              [attr.data-icon]="'warning'"
                              style="font-size: 20px"
                              [tooltipPlacement]="'right'"
                              [tooltip]="col.warningLabel"
                            >
                            </span>
                          </div>
                          <div
                            *ngIf="col.dataType === 'files' && col.show"
                            class="d-flex align-items-start"
                          >
                            <label class="mr20">
                              <input
                                type="checkbox"
                                [checked]="data['streaming']"
                                (change)="getRowAction(tab.tableId, col, data, i, 'streaming')"
                              />
                              MP3
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                [disabled]="!hasOriginalFile(data.files)"
                                [checked]="data['original']"
                                (change)="getRowAction(tab.tableId, col, data, i, 'original')"
                              />
                              AIFF/WAV
                            </label>
                            <span
                              *ngIf="!hasOriginalFile(data.files)"
                              class="material-icons-outlined pl5"
                              [attr.data-icon]="'warning'"
                              style="font-size: 20px"
                              [tooltipPlacement]="'right'"
                              tooltip="This song doesn't have AIFF/WAV"
                            >
                            </span>
                          </div>
                          <div
                            *ngIf="col.dataType === 'buttonLabels' && data[col.field]"
                            class="d-flex justify-content-center"
                            style="gap: 10px"
                          >
                            <ng-container *ngFor="let dataBtn of col.btnDisplay">
                              <button
                                class="btn primary-btn sm-btn"
                                *ngIf="
                                  dataBtn.displayIf.indexOf(data[col.field].toLowerCase()) !== -1
                                "
                                (click)="
                                  $event.stopPropagation();
                                  col.dataBtn = dataBtn;
                                  getRowAction(tab.tableId, col, data, i)
                                "
                              >
                                {{ dataBtn.label }}
                              </button>
                            </ng-container>
                          </div>
                          <div
                            *ngIf="
                              col.dataType === 'button' &&
                              data[col.field] &&
                              (!col.displayIf ||
                                (col.displayIf &&
                                  (data[col.field].toLowerCase() === col.displayIf ||
                                    col.displayIf.indexOf(data[col.field].toLowerCase()) !== -1)))
                            "
                          >
                            <button
                              class="btn primary-btn sm-btn"
                              (click)="getRowAction(tab.tableId, col, data, i)"
                            >
                              {{ col.buttonLabel }}
                            </button>
                          </div>
                          <div *ngIf="col.dataType === 'input'" class="form-group mt5 mb5">
                            <input
                              data-hj-allow
                              class="form-control"
                              id="{{ col.field }}-{{ data[col.inputFieldId] }}"
                              type="text"
                              [ngClass]="{
                                'ng-touched ng-invalid':
                                  (!data[col.field] || data[col.field] === '') &&
                                  col.isRequiredInput
                              }"
                              (keydown)="$event.stopPropagation()"
                              (keyup)="onInputChange($event, tab.tableId, col, data, i)"
                              value="{{ data[col.field] }}"
                              style="width: 95%"
                            />
                          </div>
                          <div *ngIf="col.dataType === 'textArea'" class="form-group mt5 mb5">
                            <textarea
                              class="form-control"
                              id="{{ col.field }}-{{ data[col.inputFieldId] }}"
                              value="{{ data[col.field] }}"
                              rows="3"
                              style="width: 95%; border-color: lightgrey"
                              [ngClass]="{
                                'ng-touched ng-invalid':
                                  (!data[col.field] || data[col.field] === '') &&
                                  col.isRequiredInput
                              }"
                              (keydown)="$event.stopPropagation()"
                              (keyup)="
                                onInputChange($event, tab.tableId, col, data, i);
                                $event.stopPropagation()
                              "
                            ></textarea>
                          </div>
                          <div *ngIf="col.dataType === 'array'">
                            <div
                              *ngFor="let subCol of col.subColumns"
                              style="display: inline-block"
                            >
                              <a *ngIf="subCol.dataType === 'boolean'" class="icon-container">
                                <i
                                  *ngIf="data[subCol.field] === false"
                                  class="{{ subCol.iconFalse }} table-icon"
                                  disabled="true"
                                  (click)="getRowAction(tab.tableId, subCol, data, i)"
                                ></i>
                                <i
                                  *ngIf="data[subCol.field] === true"
                                  class="{{ subCol.iconTrue }} table-icon"
                                  disabled="true"
                                  (click)="getRowAction(tab.tableId, subCol, data, i)"
                                ></i>
                              </a>
                              <p *ngIf="subCol.dataType === 'text'">{{ data[subCol.field] }}</p>
                            </div>
                          </div>
                          <div *ngIf="col.dataType === 'textArray'">
                            <div *ngFor="let text of data[col.field]">
                              <p>{{ text }}</p>
                            </div>
                          </div>
                          <div
                            *ngIf="col.dataType === 'objectsArray'"
                            class="mt10 mb10 objectsArray"
                          >
                            <div *ngFor="let object of data[col.field]" class="mt5 mb5">
                              <div *ngFor="let property of col.objectProperties">
                                <p *ngIf="property.label">{{ property.label }} :</p>
                                <p *ngIf="property.dataType === 'text' && object[property.field]">
                                  {{ object[property.field] }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="col.dataType === 'stringsArray'" class="mt10 mb10">
                            <div *ngFor="let string of data[col.field]" class="mt5 mb5">
                              <p>{{ string }}</p>
                            </div>
                          </div>
                          <div *ngIf="col.dataType === 'length'">
                            <p>{{ data[col.field].length }}</p>
                          </div>
                          <div
                            style="overflow: visible"
                            *ngIf="col.dataType === 'multiselectDropdown'"
                          >
                            <angular2-multiselect
                              [data]="col.dropdownData"
                              [settings]="col.dropdownSettings"
                              [(ngModel)]="data[col.field]"
                            >
                            </angular2-multiselect>
                          </div>
                          <a *ngIf="col.tooltipInfo">
                            <i
                              class="fa fa-info-circle pl5"
                              [ngClass]="{ 'primary-link': !col.noClass }"
                              [tooltip]="tolTemplate"
                            ></i>
                            <ng-template #tolTemplate>
                              <div style="text-align: left" *ngFor="let info of col.tooltipInfo">
                                <p *ngIf="info === 'originSubmissionId' && data[info]">
                                  <br />
                                  This track was submmited to another project originally
                                </p>
                                <p *ngIf="info !== 'originSubmissionId'">{{ data[info] }}</p>
                              </div>
                            </ng-template>
                          </a>
                          <a
                            *ngIf="col.subItemsLabel && data['subItems']?.length > 0"
                            class="subItemLink pt5 pl5"
                            (click)="getRowAction(tab.tableId, col, data, i, 'subItems')"
                          >
                            <i
                              class="fa fa-chevron-right"
                              [ngClass]="{
                                'fa-chevron-right': !data.isSubItemShow,
                                'fa-chevron-down': data.isSubItemShow
                              }"
                            ></i>
                            <label>{{
                              '+ ' + (data['subItems']?.length || 0) + ' ' + col.subItemsLabel
                            }}</label>
                          </a>
                        </ng-template>
                      </td>
                    </ng-template>
                    <div *cdkDragPreview class="pl10 pt10 pr10 pb0">
                      <p>{{ data[tab.dragPreviewField] | hideExtension }}</p>
                    </div>
                  </tr>
                </ng-template>
                <ng-template #mobileRow>
                  <tr
                    *ngFor="
                      let data of tab.dataTable
                        | paginate
                          : {
                              itemsPerPage: tab.settings?.paging?.limit,
                              currentPage: tab.settings?.paging?.page,
                              totalItems: tab.settings?.paging?.totalItems + 1
                            };
                      let i = index
                    "
                    class="items-row"
                    [ngClass]="{ activeRow: data.hover }"
                    (click)="onRowSelected(tab, i, data)"
                    (mouseup)="onRowMouseUp($event, data)"
                    cdkDrag
                    [cdkDragData]="data"
                    (mouseenter)="onHover(data)"
                    (mouseleave)="onHoverOut(data)"
                    (cdkDragStarted)="onDragStarted(data)"
                    (cdkDragDropped)="onDragDropped()"
                    [cdkDragDisabled]="!dragDropComponent"
                  >
                    <td style="padding-left: 10px; padding-top: 5px; padding-bottom: 5px">
                      <div class="row ml0 mr0 pb10">
                        <div class="titleCard col-xs-10 pl0">
                          <app-field-table
                            [col]="tab.settings?.mobileCard?.title"
                            [data]="data"
                          ></app-field-table>
                        </div>
                        <div
                          class="iconTop col-xs-2"
                          style="text-align: end"
                          *ngIf="tab.settings?.mobileCard?.iconTop"
                        >
                          <app-field-table
                            [col]="tab.settings?.mobileCard?.iconTop"
                            [data]="data"
                            [tabID]="tab.tableId"
                            (rowAction)="getRowActionEmit($event)"
                          ></app-field-table>
                        </div>
                        <div *ngIf="tab.settings.mobileCard.subTitle" class="subTitle">
                          <p class="col-xs-12 pl0" style="font-style: italic; font-size: 1.1rem">
                            {{ data[tab.settings.mobileCard.subTitle[0]?.field] }}
                            <span *ngIf="tab.settings?.mobileCard?.subTitle[1]?.showDate">
                              - {{ data['createdAt'] | date : 'MM-dd-yyyy' }}</span
                            >
                          </p>
                        </div>
                      </div>
                      <div class="row ml0 mr0 bodyCard">
                        <div
                          [ngClass]="item.classLayout"
                          *ngFor="let item of tab.settings?.mobileCard.body"
                        >
                          <app-field-table
                            *ngIf="item.show"
                            [col]="item"
                            [data]="data"
                            [tabID]="tab.tableId"
                            (rowAction)="getRowActionEmit($event)"
                          ></app-field-table>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <tr *ngIf="tab.dataTable?.length === 0 && !tab.loading">
                  <p class="loadingData mt10">{{ tab.emptyText || 'No items to show' }}</p>
                </tr>
                <tr *ngIf="tab.hasOwnProperty('loading') && tab.loading === true">
                  <p class="loadingData mt10">loading...</p>
                </tr>
              </tbody>
            </ng-container>
          </table>
        </div>
      </div>
      <div class="mt10 white-form" *ngIf="tab.settings?.pagination && tab.dataTable?.length > 0">
        <pagination
          (pageChanged)="onPageChanged($event)"
          [maxSize]="15"
          [boundaryLinks]="true"
          [itemsPerPage]="tab.settings?.paging?.limit"
          [totalItems]="tab.settings?.paging?.totalItems"
          previousText="&lsaquo;"
          nextText="&rsaquo;"
          firstText="&laquo;"
          lastText="&raquo;"
        >
        </pagination>
        <div class="ng-table-counts btn-group pull-right mt20">
          <button
            type="button"
            class="btn btn-default border-radius"
            *ngFor="let count of [10, 25, 50, 100]"
            [ngClass]="{ active: tab.settings?.paging?.limit === count }"
            (click)="changeLimit(count)"
          >
            <span class="ng-binding">{{ count }}</span>
          </button>
        </div>
      </div>
    </div>
  </tab>
</tabset>
